
export default {
  "server_global_variable" : "__viewdata",
  "development": {
    "authorizeNet": {
      "clientKey": "9MdvcaRRX29Zru5RMgrnu5Gq6UB5fCG3U2Zug6c7q5wVgW38FQSX8gF6Y4vN2a42",
      "apiLoginId": "86Yr2KsF",
      "script": "https://jstest.authorize.net/v1/Accept.js",
      "env": "testing"
    },
    "paypal" : {
      "sandbox": "ASeMZnMwFEXm2qBBQVr0wfvdZbxJib7lDdpd_vXxGo8nHVtEJ5aZez0wR0OdShYveLa3iHnXebaPxcl1",
      "production": "AYegzPrM6B5FKvAY3qeti51AvuZU9dLOTw_wqwurQTcIzmfG5_UWcC5IWQHDez1d2oH_NxAxn3D3Ceas",
      "env": "sandbox"
    }
  },

  "production": {
    "authorizeNet": {
      "clientKey": "7zr8EF4VQ2urETy4kF3Pw43KWdcdwnkqK53YdEt57RrASw5TdevpHtcAY79b5sH8",
      "apiLoginId": "3SuW7m32",
      "script": "https://js.authorize.net/v1/Accept.js",
      "env": "production"
    },

    "paypal" : {
      "sandbox": "ASeMZnMwFEXm2qBBQVr0wfvdZbxJib7lDdpd_vXxGo8nHVtEJ5aZez0wR0OdShYveLa3iHnXebaPxcl1",
      "production": "AYegzPrM6B5FKvAY3qeti51AvuZU9dLOTw_wqwurQTcIzmfG5_UWcC5IWQHDez1d2oH_NxAxn3D3Ceas",
      "env": "production"
    }
  }
};
import Container from "es-container";

import config from './config';

export default new Container({
  state: {...window[config.server_global_variable]},
  debug: import.meta.env.DEV,
  config: config[import.meta.env.MODE]
});

console.log('Debug/Env', import.meta.env.DEV,import.meta.env.MODE )


(function() {
  const store = window.__viewdata;

  /**
   * Search action from the top bar
   * @returns {boolean}
   */
  window.doSearch = function() {
    let keywords = document.getElementById('searchInput')?.value;
    if (!keywords) {
      alert('Please enter a search term');
      return;
    }
    window.location.href = '/c3/catalog/search/?k=' + keywords;
    return false;
  }

  /**
   * Listening to enter key
   * @param e
   * @returns {boolean}
   */
  window.onSearchEnter = function(e) {
    if(e.key === 'Enter') {
      return doSearch()
    }
  }

  /**
   * Add to cart fucntionalities
   */
  window.addToCart = function() {
    let qty = document.getElementById('productQty').value;
    let id = document.getElementById('productId').value;

    window.location = '/c3/cart/?p=' + id + '&q=' + qty;
  }

  window.incrementCart = function() {
    let el = document.getElementById('productQty');
    el.value = parseInt(el.value) + 1;
  }

  window.decrementCart = function() {
    let el = document.getElementById('productQty');
    el.value = Math.max(1, parseInt(el.value) - 1);
  }

  window.changeCountry = function() {
    alert('country changed')
  }

  window.countryStateElementDisplay = function(el) {
    let country = el.value;
    if(!el.dataset.stateSelect || !el.dataset.stateText) {
      console.log('data is not provided for element', el);
      return
    }

    let elStateSelect = document.querySelector(el.dataset.stateSelect);
    let elStateText = document.querySelector(el.dataset.stateText);

    if(country === 'United States') {
      elStateText.style.display = 'none';
      elStateSelect.style.display = 'block';
      elStateText.disabled = true;
      elStateSelect.disabled = false;

    } else {
      elStateText.disabled = false;
      elStateSelect.disabled = true;
      elStateSelect.style.display = 'none';
      elStateText.style.display = 'block';
    }
  }

  window.populateCountryStateSelect = function(elCountry, elState, callback) {
    const countryValue = elCountry.getAttribute('value');
    const stateValue = elState.getAttribute('value');
    let opt;
    for(let country of Object.values(store.countries)) {
      opt = new Option(country.printable_name, country.printable_name);
      if(country.printable_name === countryValue) {
        opt.selected = true;
      }
      elCountry.add(opt);
    }

    let stateName;
    for(let stateCode in store.states) {
      stateName = store.states[stateCode]
      opt = new Option(stateName, stateCode);
      if(stateCode === stateValue) {
        opt.selected = true;
      }
      elState.add(opt);
    }

    countryStateElementDisplay(elCountry)
  }

  window.populateSelectControl = function(el, options, clear = true) {
    if(clear) {
      el.innerHTML = '';
    }
    let selectedValue = el.getAttribute('value');
    let option;
    el.add(new Option('---', ''))
    for(let value of Object.keys(options)) {
      let label = options[value]
      option = new Option(value, label);
      if(value === selectedValue) {
        option.selected = true;
      }
      el.add(option);
    }
  }
})();

// import utils from "~/app/utils";
// import moment from "moment/moment";
import store from './store'


export default {
  install(Vue, options) {
    console.log("In App Plugin");
    Vue.filter('capitalize', function (value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    });
    
    Vue.filter('money', function (value, symbol = '$') {
      let amount;
      if (!value) amount = 0;
      else amount = +value;
    
      return symbol + amount.toFixed(2);
    });
    
    Vue.filter('readable', function (value) {
      if (!value) return '';
      value = value.toString();
      return _.startCase(value);
    });
    
    Vue.filter('format-date', function (value, format) {
      if (!value) return '';
      value = value.toString();
      return moment().format(format);
    });
    
    
    Vue.prototype.$app = store;
    // Vue.prototype.$util = utils;
  }
};